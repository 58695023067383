import { render, staticRenderFns } from "./Social.vue?vue&type=template&id=db4c3e54"
import script from "./Social.vue?vue&type=script&lang=js"
export * from "./Social.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runcloud/webapps/demo-2-jetxplatform/releases/20250205105538/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db4c3e54')) {
      api.createRecord('db4c3e54', component.options)
    } else {
      api.reload('db4c3e54', component.options)
    }
    module.hot.accept("./Social.vue?vue&type=template&id=db4c3e54", function () {
      api.rerender('db4c3e54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/user/Social.vue"
export default component.exports